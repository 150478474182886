import {
  Backdrop,
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  IconButton,
  InputLabel,
  MenuItem,
  Popover,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TextField,
  Tooltip,
  Typography,
} from "@material-ui/core";
import {
  Cancel,
  Close,
  ContactSupport,
  FilterList,
  Update,
  ViewColumn,
} from "@material-ui/icons";
import SearchBar from "material-ui-search-bar";
import * as React from "react";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  GetCases,
  CancelCase,
  RequestUpdateCase,
  GetCaseTypes,
} from "../../services/CustomerService";
import { action_types } from "../../types/ActionTypes";
import {
  ApplicationState,
  Case,
  CaseType,
  defaultPermission,
  initialCaseState,
  Permission,
} from "../../types/Master";
import "../index.css";
import {
  EnhancedTableHead,
  getComparator,
  HeadCell,
  OrderType,
  stableSort,
  StyledTableRow,
} from "../shared/Table";
import {
  CaseStatusCodes,
  getCaseTypeByLanguage,
  getDatetime,
  getStatus,
  getStatusValue,
  getUserPermission,
} from "../../services/Shared";
import { useHistory } from "react-router-dom";
import CreateCase from "./CreateCase";
import ToastMessage from "../shared/ToastMessage";

interface EnhancedTableToolbarProps {
  searched: string;
  userPermission: Permission;
  filteredStatuses: string[];
  translation: any;
  caseTypes: CaseType[];
  setIsCaseUpdationfailed: (val: boolean) => void;
  setCaseUpdationMessage: (val: string) => void;
  requestSearch: (searchedVal: string) => void;
  cancelSearch: () => void;
  getCases: (
    isNewCaseCreated: boolean,
    isCaseUpdated: boolean,
    translation: any
  ) => void;
  requestFilter: (statusVal: string, amountVal: string) => void;
  setActiveColumns: (val: string[]) => void;
}

const defaultcolumns = [
  "TicketNumber",
  "Type",
  "Contact",
  "StatusCode",
  "CreatedOn",
];

const EnhancedTableToolbar = (props: EnhancedTableToolbarProps) => {
  const {
    searched,
    filteredStatuses,
    userPermission,
    translation,
    caseTypes,
    setIsCaseUpdationfailed,
    setCaseUpdationMessage,
    requestSearch,
    getCases,
    cancelSearch,
    requestFilter,
    setActiveColumns,
  } = props;
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );
  const [status, setStatus] = React.useState("");
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const [viewColumAnchorEl, setViewColumnAnchorEl] =
    React.useState<HTMLButtonElement | null>(null);
  const isviewColumnOpen = Boolean(viewColumAnchorEl);
  const viewColumnsPopUpId = open ? "view-column-popover" : undefined;
  const [selectedChecks, setSelectedchecks] =
    React.useState<string[]>(defaultcolumns);

  const handleFilterClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleViewColumnClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    setViewColumnAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleViewColumnsClose = () => {
    setViewColumnAnchorEl(null);
  };

  const onFilterChange = (e: any) => {
    setStatus(e.target.value as string);
  };

  const handleFilterClose = () => {
    setStatus("");
  };

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let newArray = [...selectedChecks, event.target.id];
    if (selectedChecks.includes(event.target.id)) {
      newArray = newArray.filter((day) => day !== event.target.id);
    }
    setSelectedchecks(newArray);
  };

  useEffect(() => {
    requestFilter(status, "");
  }, [status]);

  useEffect(() => {
    setActiveColumns(selectedChecks);
  }, [selectedChecks]);

  return (
    <Box className="d-flex d-flex-direction-column">
      <Box className="page-title-background">
        <Box className="container">
          <h2 className="color-green">{translation.cases}</h2>
        </Box>
      </Box>
      <Box className="container">
        <Box className="table-round-top-wrapper d-flex d-flex-justifyContent-space-between">
          <SearchBar
            value={searched}
            onChange={(searchVal) => requestSearch(searchVal)}
            onCancelSearch={() => cancelSearch()}
            className="searchbar-main w-40"
          />
          <Box className="cases-top-right-wrapper d-flex">
            <Typography>
              {status}
              {status && (
                <IconButton onClick={handleFilterClose}>
                  <Close />
                </IconButton>
              )}
            </Typography>
            <Box title="Filter" className="filter-main">
              <IconButton aria-describedby={id} onClick={handleFilterClick}>
                <FilterList />
              </IconButton>
            </Box>
            <Box title="View" className="filter-main">
              <IconButton aria-describedby={id} onClick={handleViewColumnClick}>
                <ViewColumn />
              </IconButton>
            </Box>
            {userPermission.CanCreate && (
              <CreateCase
                caseTypes={caseTypes}
                getCases={getCases}
                setIsCaseUpdationfailed={setIsCaseUpdationfailed}
                setCaseUpdationMessage={setCaseUpdationMessage}
              />
            )}
          </Box>
        </Box>
      </Box>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Box className="order-status-filter">
          <InputLabel id="status-label">{translation.type}</InputLabel>
          <Select
            labelId="status-label"
            id="status-select"
            value={status}
            label={translation.type}
            onChange={(e) => onFilterChange(e)}
            style={{ width: "100%" }}
          >
            {filteredStatuses &&
              filteredStatuses.map((item) => {
                return <MenuItem value={item}>{item}</MenuItem>;
              })}
          </Select>
        </Box>
      </Popover>
      <Popover
        id={viewColumnsPopUpId}
        open={isviewColumnOpen}
        anchorEl={viewColumAnchorEl}
        onClose={handleViewColumnsClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
      >
        <Box className="order-status-filter d-flex d-flex-direction-column">
          <InputLabel id="status-label">{translation.columns}</InputLabel>
          <FormControlLabel
            label={translation.ticketNumber}
            control={
              <Checkbox
                checked={selectedChecks.includes("TicketNumber")}
                id="TicketNumber"
                value={translation.ticketNumber}
                onChange={handleCheckboxChange}
              />
            }
          />
          <FormControlLabel
            label={translation.type}
            control={
              <Checkbox
                checked={selectedChecks.includes(translation.type)}
                id={translation.type}
                value={translation.type}
                onChange={handleCheckboxChange}
              />
            }
          />
          <FormControlLabel
            label={translation.contact}
            control={
              <Checkbox
                checked={selectedChecks.includes(translation.contact)}
                id={translation.contact}
                value={translation.contact}
                onChange={handleCheckboxChange}
              />
            }
          />
          <FormControlLabel
            label={translation.status}
            control={
              <Checkbox
                checked={selectedChecks.includes("StatusCode")}
                id="StatusCode"
                value="Status"
                onChange={handleCheckboxChange}
              />
            }
          />
          <FormControlLabel
            label={translation.createdOn}
            control={
              <Checkbox
                checked={selectedChecks.includes("CreatedOn")}
                id="CreatedOn"
                value="Created"
                onChange={handleCheckboxChange}
              />
            }
          />
        </Box>
      </Popover>
    </Box>
  );
};

const mapStateToProps = (state: ApplicationState) => ({
  isLoading: state.isLoading,
  user: state.user,
  translation: state.translation,
  country: state.country,
  language: state.language,
});

const mapDispatchToProps = (dispatch: React.Dispatch<action_types>) => {
  return {
    setLoader: (val: boolean) => dispatch({ type: "SET_LOADER", payload: val }),
    setNavigation: (val: string) =>
      dispatch({ type: "SET_NAVIGATION", payload: val }),
    setEditCaseNumber: (val: string) =>
      dispatch({ type: "SET_EDIT_CASE_NUMBER", payload: val }),
  };
};

const Cases = (props: any) => {
  const {
    isLoading,
    user,
    setLoader,
    setNavigation,
    setEditCaseNumber,
    translation,
    country,
    language,
  } = props;
  const [order, setOrder] = React.useState<OrderType>("asc");
  const [rows, setRows] = React.useState<Case[]>([]);
  const [originalRows, setOriginalRows] = React.useState<Case[]>([]);
  const [orderBy, setOrderBy] = React.useState<string>("CreatedOn");
  const [selected, setSelected] = React.useState<readonly string[]>([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [selectedCase, setSelectedCase] =
    React.useState<Case>(initialCaseState);
  const [isRequestUpdateOpen, setIsRequestUpdateOpen] = React.useState(false);
  const [isCaseUpdated, setIsCaseUpdated] = React.useState(false);
  const [isCaseUpdationfailed, setIsCaseUpdationfailed] = React.useState(false);
  const [caseUpdationMessage, setCaseUpdationMessage] =
    React.useState<string>("");
  const [searched, setSearched] = useState<string>("");
  const [filteredStatuses, setFilteredStatuses] = React.useState<string[]>([]);
  const [activeColumns, setActiveColumns] = useState<string[]>(defaultcolumns);
  const [isCancelCaseOpen, setIsCancelCaseOpen] = React.useState(false);
  const [userPermission, setUserPermission] =
    useState<Permission>(defaultPermission);
  const [caseTypes, setCaseTypes] = useState<CaseType[]>([]);
  const history = useHistory();
  const headCells: HeadCell[] = [
    {
      id: "TicketNumber",
      numeric: false,
      disableSorting: false,
      label: translation.ticketNumber,
    },
    {
      id: "Type",
      numeric: false,
      disableSorting: false,
      label: translation.type,
    },
    {
      id: "Contact",
      numeric: false,
      disableSorting: false,
      label: translation.contact,
    },
    {
      id: "StatusCode",
      numeric: false,
      disableSorting: true,
      label: translation.status,
    },
    {
      id: "CreatedOn",
      numeric: false,
      disableSorting: false,
      label: translation.createdOn,
    },
  ];

  const requestSearch = (searchedVal: string) => {
    const filteredRows = originalRows.filter((row) => {
      return row.Title.toLowerCase().includes(searchedVal.toLowerCase());
    });
    let filteredRowsByNumber = originalRows.filter((row) => {
      return row.TicketNumber.toLowerCase().includes(searchedVal.toLowerCase());
    });
    setRows(
      filteredRows && filteredRows.length > 0
        ? filteredRows
        : filteredRowsByNumber
    );
  };

  const cancelSearch = () => {
    setSearched("");
    requestSearch(searched);
  };

  const requestFilter = (statusVal: string) => {
    const filteredRows = originalRows.filter((row) => {
      return row.Type.toLowerCase().includes(statusVal.toLowerCase());
    });
    setRows(filteredRows);
  };

  const handleRequestSort = (property: string) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleUpdate = (ticketNumber: string, event: any) => {
    event && event.preventDefault();
    rows.every((item) => {
      if (item.TicketNumber == ticketNumber) {
        setSelectedCase(item);
        setEditCaseNumber(ticketNumber);
        return false;
      } else return true;
    });
  };

  const handleRequestUpdate = (ticketNumber: string) => {
    setIsRequestUpdateOpen(true);
    rows.map((n) => {
      if (n.TicketNumber == ticketNumber) setSelectedCase(n);
    });
  };

  const handleCancel = (ticketNumber: string) => {
    setIsCancelCaseOpen(true);
    rows.map((n) => {
      if (n.TicketNumber == ticketNumber) setSelectedCase(n);
    });
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (name: string) => selected.indexOf(name) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const getCaseTypeValues = (entries: Case[]) => {
    let statuses: string[] = [];
    entries.forEach((item) => {
      if (!statuses.includes(item.Type)) {
        statuses.push(item.Type);
      }
    });
    setFilteredStatuses(statuses);
  };

  useEffect(() => {
    let userPermission = getUserPermission(user, "/cases");
    if (userPermission) {
      setUserPermission(userPermission);
      if (userPermission.CanRead == false) {
        history.push("/");
      }
    }
  }, []);

  useEffect(() => {
    setNavigation("/cases");
    getCases(false, false, translation);
    GetCaseTypes()
      .then((res) => {
        if (res) setCaseTypes(res);
      })
      .catch((ex) => {
        console.error(ex);
      });
  }, []);

  const getCases = (
    isNewCaseCreated: boolean,
    isCaseUpdated: boolean,
    translation: any
  ) => {
    setLoader(true);
    GetCases(user.AccountId)
      .then((res) => {
        setLoader(false);
        if (res) {
          setRows(res);
          setOriginalRows(res);
          getCaseTypeValues(res);
        }
        if (isCaseUpdated) {
          setIsCaseUpdated(true);
          setCaseUpdationMessage(translation.caseUpdationSuccess);
        }
        if (isNewCaseCreated) {
          setIsCaseUpdated(true);
          setCaseUpdationMessage(translation.caseCreationSuccess);
        }
      })
      .catch((ex) => {
        setLoader(false);
        console.error(ex);
      });
  };

  const handleRequestUpdateSubmit = (
    event: React.FormEvent<HTMLFormElement>
  ) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    data.append("customerId", user.ID);
    data.append("caseId", selectedCase.ID);
    data.append("ownerId", selectedCase.OwnerId);
    data.append("ownerType", selectedCase.OwnerType);
    data.append("subject", translation.requestAnUpdateSubject);
    setLoader(true);
    RequestUpdateCase(data)
      .then((res) => {
        setIsRequestUpdateOpen(false);
        setLoader(false);
        setIsCaseUpdated(true);
        setCaseUpdationMessage(translation.caseUpdationSuccess);
      })
      .catch((ex) => {
        setLoader(false);
        setIsRequestUpdateOpen(false);
        setIsCaseUpdationfailed(true);
        setCaseUpdationMessage(translation.failed);
        console.error(ex);
      });
  };

  const handleCancelSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    data.append("id", selectedCase.ID);
    setLoader(true);
    CancelCase(data)
      .then((res) => {
        GetCases(user.AccountId)
          .then((res) => {
            if (res) {
              setRows(res);
              setOriginalRows(res);
            }
            setIsCaseUpdated(true);
            setCaseUpdationMessage(translation.caseCancellationSuccess);
          })
          .catch((ex) => {
            console.error(ex);
          });
        setLoader(false);
        setIsCancelCaseOpen(false);
      })
      .catch((ex) => {
        setLoader(false);
        setIsCancelCaseOpen(false);
        setIsCaseUpdationfailed(true);
        setCaseUpdationMessage(translation.failed);
        console.error(ex);
      });
  };

  const handleToastClose = () => {
    setIsCaseUpdated(false);
    setIsCaseUpdationfailed(false);
    setCaseUpdationMessage("");
  };

  return (
    <Box>
      <ToastMessage
        isOpen={isCaseUpdated || isCaseUpdationfailed}
        variant={
          isCaseUpdated ? "success" : isCaseUpdationfailed ? "danger" : ""
        }
        message={caseUpdationMessage}
        handleToastClose={handleToastClose}
      />
      <Backdrop
        style={{
          zIndex: 2250,
        }}
        open={isLoading}
      >
        <Box className="loading-icon" />
      </Backdrop>
      <React.Fragment>
        <Box className="w-100">
          <EnhancedTableToolbar
            searched={searched}
            userPermission={userPermission}
            filteredStatuses={filteredStatuses}
            translation={translation}
            caseTypes={caseTypes}
            setIsCaseUpdationfailed={setIsCaseUpdationfailed}
            setCaseUpdationMessage={setCaseUpdationMessage}
            requestSearch={requestSearch}
            getCases={getCases}
            cancelSearch={cancelSearch}
            requestFilter={requestFilter}
            setActiveColumns={setActiveColumns}
          />
          <Box className="container">
            <Box className="table-round-styling">
              <TableContainer>
                <Table aria-labelledby="tableTitle">
                  <EnhancedTableHead
                    order={order}
                    orderBy={orderBy}
                    headCells={headCells}
                    rowCount={rows.length}
                    noActionCell={false}
                    activeColumns={activeColumns}
                    translation={translation}
                    onRequestSort={handleRequestSort}
                  />
                  <TableBody>
                    {/* if you don't need to support IE11, you can replace the `stableSort` call with:
              rows.slice().sort(getComparator(order, orderBy)) */}
                    {stableSort(rows, getComparator(order, orderBy))
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((row, index) => {
                        const isItemSelected = isSelected(row.Title);

                        return (
                          <StyledTableRow
                            hover
                            role="checkbox"
                            aria-checked={isItemSelected}
                            tabIndex={index}
                            key={row.ID}
                            selected={isItemSelected}
                          >
                            {activeColumns.includes("TicketNumber") && (
                              <TableCell>
                                {row.StatusCode.toLowerCase() ==
                                CaseStatusCodes.WAITING_CUTOMER_RESPONSE ? (
                                  <a
                                    onClick={(event: any) =>
                                      handleUpdate(row.TicketNumber, event)
                                    }
                                    href="#"
                                  >
                                    {row.TicketNumber}
                                  </a>
                                ) : (
                                  row.TicketNumber
                                )}
                              </TableCell>
                            )}
                            {activeColumns.includes("Type") && (
                              <TableCell>
                                {getCaseTypeByLanguage(
                                  caseTypes,
                                  row.Type,
                                  language
                                )}
                              </TableCell>
                            )}
                            {activeColumns.includes("Contact") && (
                              <TableCell>{row.Contact}</TableCell>
                            )}
                            {activeColumns.includes("StatusCode") && (
                              <TableCell>
                                <span
                                  className={
                                    getStatus(row.StatusCode).toLowerCase() ==
                                    CaseStatusCodes.OPEN
                                      ? "open-status"
                                      : getStatus(
                                          row.StatusCode
                                        ).toLowerCase() ==
                                        CaseStatusCodes.WAITING_CUTOMER_RESPONSE
                                      ? "in-progress-status"
                                      : "close-status"
                                  }
                                >
                                  {getStatusValue(row.StatusCode, language)}
                                </span>
                              </TableCell>
                            )}
                            {activeColumns.includes("CreatedOn") && (
                              <TableCell>
                                {getDatetime(row.CreatedOn)}
                              </TableCell>
                            )}
                            <TableCell>
                              <Box className="action-buttons">
                                {userPermission.CanUpdate &&
                                  row.StatusCode.toLowerCase() !=
                                    CaseStatusCodes.CANCELLED && (
                                    <Tooltip
                                      title={translation.requestAnUpdate}
                                      className="mr-2 return-icon"
                                    >
                                      <IconButton
                                        onClick={() =>
                                          handleRequestUpdate(row.TicketNumber)
                                        }
                                      >
                                        <ContactSupport />
                                        {translation.requestAnUpdate}
                                      </IconButton>
                                    </Tooltip>
                                  )}
                                {userPermission.CanUpdate &&
                                  row.StatusCode.toLowerCase() !=
                                    CaseStatusCodes.CANCELLED && (
                                    <Tooltip
                                      title={translation.edit}
                                      className="mr-2 update-icon"
                                    >
                                      <IconButton
                                        onClick={(e) =>
                                          handleUpdate(row.TicketNumber, e)
                                        }
                                        disabled={
                                          row.StatusCode.toLowerCase() !=
                                          CaseStatusCodes.WAITING_CUTOMER_RESPONSE
                                        }
                                      >
                                        <Update />
                                        {translation.edit}
                                      </IconButton>
                                    </Tooltip>
                                  )}
                                {userPermission.CanUpdate &&
                                  row.StatusCode.toLowerCase() !=
                                    CaseStatusCodes.CANCELLED && (
                                    <Tooltip
                                      title={translation.cancel}
                                      className="cancel-icon"
                                    >
                                      <IconButton
                                        onClick={() =>
                                          handleCancel(row.TicketNumber)
                                        }
                                      >
                                        <Cancel />
                                        {translation.cancel}
                                      </IconButton>
                                    </Tooltip>
                                  )}
                              </Box>
                            </TableCell>
                          </StyledTableRow>
                        );
                      })}
                    {emptyRows > 0 && (
                      <StyledTableRow>
                        <TableCell colSpan={6} />
                      </StyledTableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={rows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                className="pagination-content"
              />
            </Box>
          </Box>
        </Box>
        <Dialog
          open={isCancelCaseOpen}
          onClose={() => setIsCancelCaseOpen(false)}
        >
          <Box component="form" onSubmit={handleCancelSubmit}>
            <DialogTitle>{translation.cancelCase}</DialogTitle>
            <DialogContent className="update-cases">
              <Box className="order-top-summary-content">
                <Box alignItems="left" className="my-2 border-bottom-grey-01">
                  <Typography className="mb-1 dark-grey-01">
                    {translation.ticketNumber}
                  </Typography>
                  <Typography className="light-grey-color-02">
                    {selectedCase.TicketNumber}
                  </Typography>
                </Box>
                <Box alignItems="left" className="my-4 update-cases-comments">
                  <TextField
                    required
                    id="outlined-multiline-static"
                    label={translation.reason}
                    name="comments"
                    multiline
                    fullWidth
                    rows={2}
                    className="p-2"
                    inputProps={{ maxLength: 2000 }}
                  />
                </Box>
              </Box>
            </DialogContent>
            <DialogActions>
              <Button
                type="submit"
                variant="contained"
                className="ms-auto btn-button btn-blue p-2 px-3"
                disabled={isLoading}
              >
                {translation.submit}
              </Button>
            </DialogActions>
          </Box>
        </Dialog>
        <Dialog
          open={isRequestUpdateOpen}
          onClose={() => setIsRequestUpdateOpen(false)}
        >
          <Box component="form" onSubmit={handleRequestUpdateSubmit}>
            <DialogTitle>{translation.requestAnUpdate}</DialogTitle>
            <DialogContent className="update-cases">
              <Box className="order-top-summary-content">
                <Box alignItems="left" className="my-2 border-bottom-grey-01">
                  <Typography className="mb-1 light-grey-color-02">
                    {translation.ticketNumber}
                  </Typography>
                  <Typography className="dark-grey-01">
                    {selectedCase.TicketNumber}
                  </Typography>
                </Box>
                <Box alignItems="left" className="my-4 update-cases-comments">
                  <TextField
                    required
                    id="request-update"
                    label={translation.description}
                    name="description"
                    multiline
                    fullWidth
                    rows={2}
                    className="p-2"
                    inputProps={{ maxLength: 2000 }}
                  />
                </Box>
              </Box>
            </DialogContent>
            <DialogActions>
              <Button
                type="submit"
                variant="contained"
                className="ms-auto btn-button btn-blue p-2 px-3"
                disabled={isLoading}
              >
                {translation.submit}
              </Button>
            </DialogActions>
          </Box>
        </Dialog>
      </React.Fragment>
    </Box>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Cases);

import * as React from "react";
import { Dispatch } from "redux";
import { NavLink } from "reactstrap";
import { Link } from "react-router-dom";
import { Box, MenuItem, Select, Typography } from "@material-ui/core";
import { connect, ConnectedProps } from "react-redux";
import { action_types } from "../../types/ActionTypes";
import { ApplicationState } from "../../types/Master";
import { getUserPermission } from "../../services/Shared";
import "../index.css";

const languages = [
  { src: require("../../images/at.png"), code: "at", country: "austria" },
  { src: require("../../images/gb.png"), code: "gb", country: "uk" },
  { src: require("../../images/be.png"), code: "be", country: "belgium" },
  { src: require("../../images/nl.png"), code: "nl", country: "netherland" },
  { src: require("../../images/ch.png"), code: "ch", country: "switzerland" },
];

const mapDispatchToProps = (dispatch: Dispatch<action_types>) => {
  return {
    logout: () => dispatch({ type: "LOGOUT", payload: undefined }),
    setLanguage: (val: { code: string; country: string }) =>
      dispatch({ type: "SET_LANGUAGE", payload: val }),
  };
};

const mapStateToProps = (state: ApplicationState) => ({
  user: state.user,
  isAuthUser: state.isAuthUser,
  selectedNavItem: state.selectedNavItem,
  language: state.language,
  country: state.country,
  translation: state.translation,
});

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

class NavMenu extends React.Component<PropsFromRedux> {
  public state = {
    isOpen: false,
    anchorEl: null,
    anchorElNav: null,
  };

  handleClick = (event: React.MouseEvent<HTMLElement>) => {
    this.setState({ anchorEl: event.currentTarget, isOpen: true });
  };

  handleClose = () => {
    this.setState({ anchorEl: null, isOpen: false });
  };

  handleCloseNavMenu = () => {
    this.setState({ anchorElNav: null, isOpen: false });
  };

  handleLogout = () => {
    this.setState({ anchorEl: null, isOpen: false });
    this.props.logout();
  };

    handleLanguageSelection = (code: string, country: string) => {       
    this.props.setLanguage({ code, country });
  };

  public render() {
    const {
      translation,
      language,
      isAuthUser,
      user,
      selectedNavItem,
      setLanguage,
    } = this.props;
    const loggedInMenu = [{ name: translation.myProfile, linkTo: "/profile" }];
    const notLoggedInMenu = [{ name: translation.signin, linkTo: "/login" }];
    const pages = [
      { name: translation.home, linkTo: "/home" },
      { name: translation.cases, linkTo: "/cases" },
      { name: translation.surveys, linkTo: "/surveys" },
      { name: translation.faq, linkTo: "/articles" },
    ];

    return (
      <Box className="header-main">
        <Box className="header-content d-flex d-flex-justifyContent-space-between">
          <Box className="container">
            <Box className="d-flex d-flex-justifyContent-space-between mt-2">
              <Box className="d-flex-column">
                <NavLink tag={Link} className="text-dark logo" to="/">
                  <img
                    src={require("../../images/logo.png")}
                    alt="Routeco"
                    className="img-fluid"
                  />
                </NavLink>
                <Box className="d-flex d-flex-align-item-center pt-2">
                  <Typography className="font-12-light px-2">Sites</Typography>
                  {languages &&
                    languages.map((item) => {
                      return (
                        <img
                          onClick={() =>
                            this.handleLanguageSelection(
                              item.code,
                              item.country
                            )
                          }
                          src={item.src}
                          alt={item.code}
                          className={`img-fluid language-icons  px-1 ${
                            this.props.country == item.country ? "active" : ""
                          }`}
                        />
                      );
                    })}
                  {this.props.country == "belgium" && (
                    <Select
                      labelId="language-label"
                      id="language-select"
                      className="language-selection px-1"
                      value={language}
                      onChange={(e) =>
                        this.handleLanguageSelection(
                          e.target.value as string,
                          this.props.country
                        )
                      }
                    >
                      <MenuItem className="language-selection-item" value="nl">
                        NL
                      </MenuItem>
                      <MenuItem className="language-selection-item" value="be">
                        FR
                      </MenuItem>
                    </Select>
                                )}
                                {this.props.country == "switzerland" && (
                                    <Select
                                        labelId="language-label"
                                        id="language-select"
                                        className="language-selection px-1"
                                        value={language}
                                        onChange={(e) =>
                                            this.handleLanguageSelection(
                                                e.target.value as string,
                                                this.props.country
                                            )
                                        }
                                    >
                                        <MenuItem className="language-selection-item" value="ch">
                                            DE
                                        </MenuItem>
                                        <MenuItem className="language-selection-item" value="be">
                                            FR
                                        </MenuItem>
                                       
                                    </Select>
                                )}
                </Box>
              </Box>
              <Box className="header-right-content d-flex d-flex-align-item-center">
                <Box className=" profile-icon">
                  <img alt="" src={require("../../images/profile-icon.png")} />
                </Box>
                {isAuthUser ? (
                  <React.Fragment>
                    {loggedInMenu.map((item) => (
                      <MenuItem
                        key={item.name}
                        onClick={this.handleClose}
                        className="nav-item"
                      >
                        <NavLink tag={Link} to={item.linkTo}>
                          {item.name}
                        </NavLink>
                      </MenuItem>
                    ))}
                    <MenuItem
                      key="logout"
                      onClick={this.handleLogout}
                      className="nav-item"
                    >
                      <NavLink tag={Link} to="/">
                        {`/ ${translation.logOut}`}
                      </NavLink>
                    </MenuItem>
                  </React.Fragment>
                ) : (
                  notLoggedInMenu.map((item) => (
                    <MenuItem
                      key={item.name}
                      onClick={this.handleClose}
                      className="nav-item"
                    >
                      <NavLink tag={Link} to={item.linkTo}>
                        {item.name}
                      </NavLink>
                    </MenuItem>
                  ))
                )}
              </Box>
            </Box>
          </Box>
        </Box>
        <Box className="">
          {isAuthUser && (
            <Box className="nav d-flex d-flex--justifyCenter">
              {pages.map((page) => {
                return (
                  getUserPermission(user, page.linkTo).CanRead &&
                  user.IsVerified && (
                    <NavLink
                      tag={Link}
                      className={`nav-content ${
                        selectedNavItem == page.linkTo ? "active" : ""
                      }`}
                      to={page.linkTo}
                    >
                      {page.name}
                    </NavLink>
                  )
                );
              })}
            </Box>
          )}
        </Box>
      </Box>
    );
  }
}

export default connector(NavMenu);

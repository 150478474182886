import gb from "./gb";
import be from "./be";
import at from "./at";
import nl from "./nl";
import fr from "./fr";
import ch from "./ch"


export default {
    gb,
    be,
    at,
    nl,
    fr,
    ch
};

import {
  CaseType,
  defaultCaseType,
  defaultPermission,
  mimeDict,
  Permission,
  User,
} from "../types/Master";
import Defines from "../config";

export const getfileType = (extension: string): string => {
  switch (extension) {
    case "txt":
      return mimeDict.txt;
      break;
    case "pdf":
      return mimeDict.pdf;
      break;
    case "docx":
      return mimeDict.docx;
      break;
    case "xlsx":
      return mimeDict.xlsx;
      break;
    case "png":
      return mimeDict.png;
      break;
    case "jpeg":
      return mimeDict.jpeg;
      break;
    case "gif":
      return mimeDict.gif;
      break;
    case "rar":
      return mimeDict.rar;
      break;
    default:
      return "";
  }
};

let dateOptions = {
  year: "numeric",
  month: "numeric",
  day: "numeric",
} as const;

export const getDate = (val: string): Date => {
  let date = val.split(" ");
  let dateArray = date[0].split("/");
  let timeArray = date[1].split(":");
  var d = new Date(
    parseInt(dateArray[2]),
    parseInt(dateArray[0]) - 1,
    parseInt(dateArray[1]),
    parseInt(timeArray[0]),
    parseInt(timeArray[1]),
    parseInt(timeArray[2])
  );
  return d;
};

export const getDatetime = (val: string): string => {
  return getDate(val).toLocaleDateString(Defines.DATE_TYPE, dateOptions);
};

export const getUserPermission = (user: User, module: string): Permission => {
  let item: Permission = { ...defaultPermission };
  if (user.Permissions && user.Permissions.length > 0) {
    switch (module.toLowerCase()) {
      case "/home":
        item.CanRead = true;
        return item;
      case "/surveys":
        item.CanRead = true;
        return item;
      case "/articles":
        item.CanRead = true;
        return item;
      case "/cases":
        user.Permissions.forEach((obj) => {
          if (obj.Entityname.toLowerCase() == "incident") item = obj;
        });
        return item;
      default:
        item.CanRead = false;
        return item;
    }
  }
  return item;
};

export const getCaseTypeByLanguage = (
  caseTypes: CaseType[],
  caseTypeName: string,
  language: string
): string => {
  let caseType: CaseType = defaultCaseType;
  caseTypes.every((item) => {
    if (item.Name == caseTypeName) {
      caseType = item;
      return false;
    } else return true;
  });
  return caseType.Name != "" ? getCaseType(caseType, language) : caseTypeName;
};

export const getCaseType = (caseType: CaseType, language: string): string => {
  switch (language) {
    case "be":
          return caseType.NameBelgium != "" ? caseType.NameBelgium : caseType.Name;
    case "fr":
          return caseType.NameBelgium != "" ? caseType.NameBelgium : caseType.Name;
    case "at":
          return caseType.NameAustria != "" ? caseType.NameAustria : caseType.Name;
    case "ch":
          return caseType.NameAustria != "" ? caseType.NameAustria : caseType.Name;
    case "nl":
      return caseType.NameNetherlands != ""
        ? caseType.NameNetherlands
        : caseType.Name;
    default:
      return caseType.Name;
  }
};

export const getSubCaseType = (
  subCaseType: CaseType,
  language: string
): string => {
  switch (language) {
    case "be":
      return subCaseType.NameBelgium != ""
        ? subCaseType.NameBelgium
              : subCaseType.Name;
      case "fr":
          return subCaseType.NameBelgium != ""
              ? subCaseType.NameBelgium
              : subCaseType.Name;
    case "at":
      return subCaseType.NameAustria != ""
        ? subCaseType.NameAustria
              : subCaseType.Name;
    case "ch":
          return subCaseType.NameAustria != ""
              ? subCaseType.NameAustria
              : subCaseType.Name;
    case "nl":
      return subCaseType.NameNetherlands != ""
        ? subCaseType.NameNetherlands
              : subCaseType.Name;      
    default:
      return subCaseType.Name;
  }
};

export const getReturnPolicyUrl = (
  language: string,
  country: string
): string => {    
  switch (country) {
    case "uk":
      return Defines.RETURN_POLICY_URL_GB;
    case "belgium":
      if (language == "nl") return Defines.RETURN_POLICY_URL_BE_DU;
      if (language == "be") return Defines.RETURN_POLICY_URL_BE_FR;    
    case "austria":
      return Defines.RETURN_POLICY_URL_AT;
    case "netherland":
          return Defines.RETURN_POLICY_URL_NL;
      case "switzerland":          
          if (language == "ch") return Defines.TERMSANDCONDITIONS_URL_CH_CH;
          if (language == "be") return Defines.TERMSANDCONDITIONS_URL_CH_FR;
    default:
      return Defines.RETURN_POLICY_URL_GB;
  }
};

export const CaseStatusCodes = {
  CANCELLED: "cancelled",
  OPEN: "open",
  NO_FAULT: "no fault of warehouse",
  PENDING_CREDIT: "pending customer credit",
  AWAITING_RETURN: "awaiting customer return",
  COLLECTION_ARRANGED_BRANCH: "collection arranged with branch",
  COLLECTION_ARRANGED_COURIER: "collection arranged with courier",
  GOODS_IN_STORE: "goods in Store awaiting rma",
  GOODS_ON_BACK_ORDER: "goods on back order",
  REPLACEMENT_SENT: "replacement sent",
  RETURN_REJECTED: "return request rejected",
  WAITING_BRANCH_RESPONSE: "waiting on branch response",
  WAITING_CUTOMER_RESPONSE: "waiting on customer response",
  AWAITING_SUPPLIER_CREDIT: "awaiting supplier credit",
  CUSTOMER_CREDIT_RAISED: "customer credit raised",
  CLOSED: "closed",
};

export const CaseRequestTypes = {
  COMPLAINT: "customer complaint",
  RETURN: "customer return",
  PICK_ERROR: "pick error",
  NPSR: "npsr (rockwell faulty product)",
};

export const getConditionOfGoods = (val: string, language: string): string => {
  let responseValue: string = "";
  switch (language) {
    case "be":
      if (
        val == "Re-saleable Condition. Seal Intact. No Marking  / No Labels"
      ) {
        responseValue =
          "Condition de revente. Sceau intact. Pas de marquage / Pas d'étiquettes";
      } else if (val == "Seal Broken, Packaging Marked / Damaged") {
        responseValue = "Sceau cassé, emballage marqué / endommagé";
      } else if (val == "Documents Missing") {
        responseValue = "Documents manquants";
      } else if (val == "Packaging Missing") {
        responseValue = "Emballage manquant";
      } else if (val == "Other Condition (Provide details in description)") {
        responseValue = "Autre condition (précisez dans la description)";
      } else responseValue = val;
          break;
      case "fr":
          if (
              val == "Re-saleable Condition. Seal Intact. No Marking  / No Labels"
          ) {
              responseValue =
                  "Condition de revente. Sceau intact. Pas de marquage / Pas d'étiquettes";
          } else if (val == "Seal Broken, Packaging Marked / Damaged") {
              responseValue = "Sceau cassé, emballage marqué / endommagé";
          } else if (val == "Documents Missing") {
              responseValue = "Documents manquants";
          } else if (val == "Packaging Missing") {
              responseValue = "Emballage manquant";
          } else if (val == "Other Condition (Provide details in description)") {
              responseValue = "Autre condition (précisez dans la description)";
          } else responseValue = val;
          break;
    case "nl":
      if (
        val == "Re-saleable Condition. Seal Intact. No Marking  / No Labels"
      ) {
        responseValue = "verkoopbaar, seal intact, geen markering/ labels";
      } else if (val == "Seal Broken, Packaging Marked / Damaged") {
        responseValue = "seal verbroken, markering/ beschadigd";
      } else if (val == "Documents Missing") {
        responseValue = "documenten ontbreken";
      } else if (val == "Packaging Missing") {
        responseValue = "verpakking ontbreekt";
      } else if (val == "Other Condition (Provide details in description)") {
        responseValue = "Conditie overig (graag aangeven)";
      } else responseValue = val;
      break;
    case "at":
      if (
        val == "Re-saleable Condition. Seal Intact. No Marking  / No Labels"
      ) {
        responseValue =
          "Wiederverkaufbarer Zustand. Siegel intakt. Keine Markierung / keine Etiketten";
      } else if (val == "Seal Broken, Packaging Marked / Damaged") {
        responseValue = "Siegel gebrochen, Verpackung markiert / beschädigt";
      } else if (val == "Documents Missing") {
        responseValue = "Dokumente fehlen";
      } else if (val == "Packaging Missing") {
        responseValue = "Verpackung fehlt";
      } else if (val == "Other Condition (Provide details in description)") {
        responseValue =
          "Sonstiger Zustand (Details in der Beschreibung angeben)";
      } else responseValue = val;
          break;
      case "ch":
          if (
              val == "Re-saleable Condition. Seal Intact. No Marking  / No Labels"
          ) {
              responseValue =
                  "Wiederverkaufbarer Zustand. Siegel intakt. Keine Markierung / keine Etiketten";
          } else if (val == "Seal Broken, Packaging Marked / Damaged") {
              responseValue = "Siegel gebrochen, Verpackung markiert / beschädigt";
          } else if (val == "Documents Missing") {
              responseValue = "Dokumente fehlen";
          } else if (val == "Packaging Missing") {
              responseValue = "Verpackung fehlt";
          } else if (val == "Other Condition (Provide details in description)") {
              responseValue =
                  "Sonstiger Zustand (Details in der Beschreibung angeben)";
          } else responseValue = val;
          break;
    default:
      responseValue = val;
      break;
  }
  return responseValue;
};

export const getStatus = (val: string): string => {
  if (
    val.toLowerCase() == CaseStatusCodes.NO_FAULT ||
    val.toLowerCase() == CaseStatusCodes.PENDING_CREDIT ||
    val.toLowerCase() == CaseStatusCodes.OPEN
  ) {
    return "Open";
  } else return val;
};

export const getStatusValue = (val: string, language: string): string => {
  if (
    val.toLowerCase() == CaseStatusCodes.NO_FAULT ||
    val.toLowerCase() == CaseStatusCodes.PENDING_CREDIT ||
    val.toLowerCase() == CaseStatusCodes.OPEN
  ) {
    switch (language) {
      case "at":
            return "Offen";
        case "ch":
            return "Offen";
      case "be":
            return "Ouvert";
        case "fr":
            return "Ouvert";
      default:
        return "Open";
    }
  } else if (val.toLowerCase() == CaseStatusCodes.AWAITING_RETURN) {
    switch (language) {
      case "at":
            return "Warten auf Kundenretoure";
      case "ch":
            return "Warten auf Kundenretoure";
      case "be":
            return "En attente de retour client";
        case "fr":
            return "En attente de retour client";
      case "nl":
        return "Wacht op retourzending klant";
      default:
        return val;
    }
  } else if (val.toLowerCase() == CaseStatusCodes.COLLECTION_ARRANGED_BRANCH) {
    switch (language) {
      case "gb":
        return "Collection Arranged with Service Centre";
      case "at":
            return "Abholung mit Niederlassung vereinbart";
        case "ch":
            return "Abholung mit Niederlassung vereinbart";
      case "nl":
        return "Afhaal met Branch geregeld";
      case "be":
            return "Collecte organisée avec le centre de service";
        case "fr":
            return "Collecte organisée avec le centre de service";
      default:
        return val;
    }
  } else if (val.toLowerCase() == CaseStatusCodes.COLLECTION_ARRANGED_COURIER) {
    switch (language) {
      case "at":
            return "Abholung mit Spediteur vereinbart";
        case "ch":
            return "Abholung mit Spediteur vereinbart";
      case "nl":
        return "Afhaal met vervoerder geregeld";
      case "be":
            return "Collection arrangée avec Courrier";
        case "fr":
            return "Collection arrangée avec Courrier";
      default:
        return val;
    }
  } else if (val.toLowerCase() == CaseStatusCodes.GOODS_IN_STORE) {
    switch (language) {
      case "at":
            return "Ware eingetroffen, warten auf Bestätigung des Herstellers";
        case "ch":
            return "Ware eingetroffen, warten auf Bestätigung des Herstellers";
      case "nl":
        return "Goederen binnen, wachten op RMA leverancier";
      case "be":
            return "Marchandises en magasin en attente de RMA";
        case "fr":
            return "Marchandises en magasin en attente de RMA";
      default:
        return val;
    }
  } else if (val.toLowerCase() == CaseStatusCodes.GOODS_ON_BACK_ORDER) {
    switch (language) {
      case "at":
            return "Waren in Nachbestellung";
        case "ch":
            return "Waren in Nachbestellung";
      case "nl":
        return "Goederen in backorder";
      case "be":
            return "Marchandises en rupture de stock";
        case "fr":
            return "Marchandises en rupture de stock";
      default:
        return val;
    }
  } else if (val.toLowerCase() == CaseStatusCodes.REPLACEMENT_SENT) {
    switch (language) {
      case "at":
            return "Ersatz zugeschickt";
        case "ch":
            return "Ersatz zugeschickt";
      case "nl":
        return "Vervanging verstuurd";
      case "be":
            return "remplacement envoyé";
      case "fr":
            return "remplacement envoyé";
      default:
        return val;
    }
  } else if (val.toLowerCase() == CaseStatusCodes.RETURN_REJECTED) {
    switch (language) {
      case "at":
            return "Retourenanfrage abgelehnt";
        case "ch":
            return "Retourenanfrage abgelehnt";
      case "nl":
        return "Retouraanvraag afgewezen";
      case "be":
            return "Demande de retour rejetée";
        case "fr":
            return "Demande de retour rejetée";
      default:
        return val;
    }
  } else if (val.toLowerCase() == CaseStatusCodes.WAITING_BRANCH_RESPONSE) {
    switch (language) {
      case "gb":
        return "Waiting for Service Centre Response";
      case "at":
            return "Warten auf Antwort von der Niederlassung";
        case "ch":
            return "Warten auf Antwort von der Niederlassung";
      case "nl":
        return "Wacht op reactie Branch";
      case "be":
            return "En attente de la réponse du centre de service";
        case "fr":
            return "En attente de la réponse du centre de service";
      default:
        return val;
    }
  } else if (val.toLowerCase() == CaseStatusCodes.WAITING_CUTOMER_RESPONSE) {
    switch (language) {
      case "gb":
        return "Waiting for Customer Response";
      case "at":
            return "Warten auf Kundenantwort";
        case "ch":
            return "Warten auf Kundenantwort";
      case "nl":
        return "Wacht op reactie klant";
      case "be":
            return "Attente de la réponse du client";
        case "fr":
            return "Attente de la réponse du client";
      default:
        return val;
    }
  } else if (val.toLowerCase() == CaseStatusCodes.AWAITING_SUPPLIER_CREDIT) {
    switch (language) {
      case "at":
            return "Warten auf Lieferantengutschrift";
        case "ch":
            return "Warten auf Lieferantengutschrift";
      case "nl":
        return "Wacht op credit leverancier";
      case "be":
            return "En attente de crédit fournisseur";
        case "fr":
            return "En attente de crédit fournisseur";
      default:
        return val;
    }
  } else if (val.toLowerCase() == CaseStatusCodes.CUSTOMER_CREDIT_RAISED) {
    switch (language) {
      case "at":
            return "Kundengutschrift erstellt";
        case "ch":
            return "Kundengutschrift erstellt";
      case "nl":
        return "Credit klant gemaakt";
      case "be":
            return "Crédit client levé";
        case "fr":
            return "Crédit client levé";
      default:
        return val;
    }
  } else return val;
};

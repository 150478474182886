export default {
  account: "Compte",
  action: "Action",
  accountNumber: "Numéro de compte",
  additionalDetails: "Entrez des détails supplémentaires",
  addSalesLine: "Ajouter une ligne de commande",
  addAttachments: "Ajouter des pièces jointes",
  addressName: "Nom de l'adresse (obligatoir",
  addressNameValidation: "Le nom de l'adresse est obli",
  addressLine1Validation: "La ligne d'adresse 1 est obligatoire",
  addressLine2Validation: "La ligne d'adresse 2 est obligatoire",
  addressLine3Validation: "La ligne d'adresse 3 est obligatoire",
  addressPostCodeValidation: "* Postcode is verplicht",
  AlreadyRegistered:"Vous êtes déjà inscrit sur le système, veuillez effectuer une réinitialisation du mot de passe pour accéder à votre compte.",
  businessPhone: "Téléphone professionnel",
  cancel: "Annuler",
  cases: "Cas",
  caseType: "Type de cas (obligatoire)",
  caseTypeValidation: "Le type de cas est requi",
  caseCreationSuccess: "Cas créé avec succès",
  caseUpdationSuccess: "Cas mis à jour avec succès",
  caseCancellationSuccess: "Cas annulé avec succès",
  cancelCase: "Annuler le cas",
  changePassword: "Changer le mot de passe",
  contact: "Contact",
  collectionAddress: "Sélectionnez l'adresse de collecte (obligatoire)",
  collectionAddressNameValidation: "Le nom de l'adresse de collecte est obligatoir",
  collectionAddressValidation: "L'adresse de collecte est obligatoire",
  collectionAddressNotFound:
    "Si l'adresse de collecte n'a pas été trouvée, sélectionnez le commutateur à bascule",
  completeSurvey: "Compléter l'enquête",
  columns: "Colonnes",
  confirmPassword: "Confirmez le mot de passe",
  conditionOfGoods: "Condition des marchandises (obligatoire)",
  conditionOfGoodsValidation: "L'état des marchandises est obligatoire",
  createAccount: "CRÉER UN COMPTE",
  createdOn: "Gemaakt op",
  create: "Créer",
  created:"Créé le",
  createCase: "Créer un nouveau cas",
  createCaseInfo:
    "Veuillez ajouter les détails requis et cliquer sur enregistrer pour créer un nouveau cas.",
  currentPassword: "Mot de passe actuel",
  description: "Description",
  descriptionValidation: "Des détails supplémentaires sont obligatoire",
  deliveryAddress: "Sélectionnez l'adresse de livraison (obligatoire)",
  deliveryAddressNameValidation: "Le nom de l'adresse de livraison est obligatoire",
  deliveryAddressValidation: "L'adresse de livraison est obligatoire",
  deliveryAddressNotFound:
    "Si l'adresse de livraison n'est pas trouvée, sélectionnez la case à cocher",
  edit: "Modifier",
  emailAddress: "Adresse e-mail",
  emailAlreadyExistMsg: "L'e-mail existe déjà",
  emailValidationMsg: "Adresse e-mail incorrect",
  existingsignIn: "Utilisateur existant ? Connectez-vous",
  faq: "FAQ",
  failed: "Échoué",
  faultDetails: "Détails du défaut (obligatoire)",
  faultValidation: "Les détails du défaut sont obligatoires",
  firstName: "Prénom",
  forgetPassword: "Mot de passe oublié",
  forgetPasswordLinkSuccess: "Lien d'activation envoyé à votre courrier !",
  home: "Page d'accueil",
  jobTitle: "Fonction",
  lastName: "Nom de famille",
  language:"Langue",
  logOut: "Déconnexion",
  myProfile: "Mon compte",
  myDetails: "Mes coordonnées",
  mobilePhone: "Téléphone mobile",
  mobileValidationMsg: "Numéro de mobile incorrect",
  newHere: "On dirait que vous êtes nouveau ici !",
  newPassword: "Nouveau mot de passe",
  newToPortal: "Nouveau sur Portal ?",
  noOrdersFound:
    "Aucune ligne de commande trouvée qui soit éligible pour un retour",
  noOrderAdded: "Il faut ajouter une ou plusieurs lignes de commande",
  noSurvey: "Pas d'enquête",
  no: "Non",
  orderNumber: "Numéro de commande",
  partNumber: "Référence de la pièce commandée",
  partNumberReceieved: "Référence de la pièce reçue",
  password: "Mot de passe",
  passwordUpdateSuccess:
    "Votre mot de passe a été mis à jour avec succès, veuillez vous connecter à nouveau avec un nouveau mot de passe.",
  passwordUpdateFail:
    "La mise à jour du mot de passe a échoué. Essayez à nouveau après un certain temps.",
  passwordMismatch: "Les mots de passe ne correspondent",
  phone: "Téléphone",
  phoneValidationMsg: "Numéro de téléphone incorrect",
  pincode: "Pincode (obligatoire)",
  profileUpdateSuccess: "Profil mis à jour avec succès",
  profileUpdateFail: "Erreur dans la mise à jour du profil",
  profileVerificationSuccess: "L'email de vérification a été envoyé avec succès",
  profileVerificationFail: "Erreur dans l'envoi du lien de vérification",
  passwordPolicyMessage:
    "Le mot de passe doit contenir 8 caractères avec au moins 1 majuscule, 1 minuscule, 1 caractère spécial et 1 caractère numérique",
  product: "Produit",
  quantity: "Quantité",
  quantityValidation:
    "La quantité retournée doit être inférieure à la quantité commandée.",
  returnReason: "Raison du retour (obligatoire)",
  returnReasonValidation: "Le motif de retour est obligatoire",
  receivedQty: "Ontvangen hoeveelheid",
  returnQty: "Quantité de retour",
  reason: "Raison",
  resetPasswordfailed: "La réinitialisation du mot de passe a échoué, veuillez vérifier l'adresse électronique saisie.",
  replacementRequired: "Remplacement nécessaire?",
  requestAnUpdate: "Demander une mise à jour",
  requestAnUpdateSubject: "Demande de mise à jour par le client",
  returnTerms: "Politique de retour Routeco acceptée",
  rigisterFailed: "L'enregistrement a échoué",
  rigisterSuccess: "Enregistrement réussi",
  RegistrationSuccess:"Enregistrement effectué avec succès",
  seriesLetter: "Lettre de série (obligatoire)",
  seriesValidation: "Une lettre de série est obligatoire",
  salesOrder: "Commande",
  sendVerifyMail: "Envoyer le courrier de vérification",
  selectOrderLine: "Sélectionnez un produit",
  selectOrderLineHelp:
    "Sélectionnez chaque produit et la quantité que vous souhaitez retourner, puis cliquez sur le bouton Ajouter une ligne de commande.",
  selectedOrder: "Commande sélectionnée.",
  search: "Recherche",
  searchOrder: "Entrez le numéro de commande",
  searchOrderHelpText:
    "Entrez le numéro de la commande de vente ou de la commande d'achat. Les commandes de vente peuvent être recherchées à l'aide du numéro de la commande de vente ou de la commande d'achat.",
  sites: "Sites",
  signin: "Se connecter",
  signUp: "S'inscrire",
  signUpDetails:
    "En créant un compte sur notre site web, vous pourrez accéder à toutes les affaires liées à votre compte, créer de nouvelles affaires et suivre leur état d'avancement.",
  signIntoAccount: "Connectez-vous à votre compte",
  signInfailed: "Nom d'utilisateur ou mot de passe incorrect",
  street1: "Rue 1 (obligatoire)",
  street2: "Rue 2 (obligatoire)",
  street3: "Rue 3 (obligatoire)",
  status: "Statut",
  surveys: "Enquêtes",
  submit: "Soumettre",
  suffix: "Suffix",
  ticketNumber: "Numéro de cas",
  type: "Type de cas",
  update: "Mise à jour",
  updateCase: "Mise à jour du cas",
  updatePassword: "Mise à jour du mot de passe",
  verifySuccess: "Vérification complétée",
  verifyFailed: "Vérification échouée",
  VerificationValidation:"Il y a eu un problème pendant la vérification. Un membre de notre équipe va prendre contact avec vous pour terminer la vérification.",
  yes: "Oui",
};
